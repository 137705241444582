const financialPlanOverviewAddNewDropdownOptions = [
  {
    title: 'startupCost',
    iconPath: 'icons/dropdowns/set-up/startup-costs',
    command: 'addStartupCostVisible',
    type: 'startupCost',
    paid: false
  },
  {
    title: 'financingOption',
    iconPath: 'icons/dropdowns/set-up/financing',
    command: 'addFinancingVisible',
    type: 'financing',
    paid: false
  },
  {
    title: 'payrollExpense',
    iconPath: 'icons/dropdowns/set-up/staff-and-payroll',
    command: 'addStaffAndPayrollVisible',
    type: 'staffAndPayroll',
    paid: false
  },
  {
    title: 'revenueStream',
    iconPath: 'icons/dropdowns/forecast/revenue-streams',
    command: 'addRevenueStreamVisible',
    type: 'revenueStream',
    paid: false
  },
  {
    title: 'directCost',
    iconPath: 'icons/dropdowns/forecast/direct-costs',
    command: 'addDirectCostVisible',
    type: 'directCost',
    paid: true
  },
  {
    title: 'marketingCost',
    iconPath: 'icons/dropdowns/forecast/marketing-budget',
    command: 'addMarketingElementVisible',
    type: 'marketingCost',
    paid: true
  },
  {
    title: 'otherOverhead',
    iconPath: 'icons/dropdowns/forecast/other-overheads',
    command: 'addOtherOverheadVisible',
    type: 'otherOverhead',
    paid: true
  }
]

export default financialPlanOverviewAddNewDropdownOptions
